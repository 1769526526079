import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Menu } from '../interfaces/menu';

@Injectable({
  providedIn: 'root'
})

export class MenuService {

   // Array de menus por modulo
   //DATO EN MEMORIA - ACCESIBLE DESDE CUIALQUIER PARTE DE LA APP
   private menuActualSub: BehaviorSubject<Menu[]> = new BehaviorSubject<Menu[]>([]);
   public readonly accionesMenuActualObservable: Observable<Menu[]> = this.menuActualSub.asObservable();

  constructor() { }

  //SET
  asignarMenuActual = (menu: Menu[]): void =>  {
    this.menuActualSub.next(menu);
  }

  //GET
  obtenerMenu = () => {
    return this.menuActualSub.value;
  }

}
