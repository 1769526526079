<mat-form-field
  [formGroup]="formData"
  fxFlex="100"
  class="select2 w-full"
  appearance="outline"
>
  <mat-label>{{ leyenda }}</mat-label>

  <mat-select
    formControlName="elementoCtrl"
    [placeholder]="placeholder || leyenda"
    #singleSelect
    [ngStyle]="{ 'text-transform': tipografia }"
  >
    <mat-option>
      <ngx-mat-select-search
        [formControl]="filtroElementosCtrl"
        placeholderLabel="{{ placeholder || leyenda }}"
        [noEntriesFoundLabel]="'Sin resultados'"
      ></ngx-mat-select-search>
    </mat-option>

    <mat-option
      *ngFor="let admision of elementosFiltrados | async"
      [value]="admision"
      [ngStyle]="{ 'text-transform': tipografia }"
    >
      {{ admision.nombre }}
    </mat-option>
  </mat-select>
</mat-form-field>
