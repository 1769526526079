import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { AuthInt } from '../objects/auth.interface';
import { Usuario } from '../objects/usuario.interface';


@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private baseUrl = environment.base_url;

  constructor(
    private http: HttpClient
  ) { }

  
  login = (usuario: AuthInt) => {
    //Definir la ruta del backend en especifico
    const url = `${this.baseUrl}/api/auth/login`;

    return this.http.post(url,usuario).pipe(
        tap( (resp:any) => resp)
      );

  }

  registro = (usuario: Usuario) => {
    const url = `${this.baseUrl}/api/usuarios`;

    return this.http.post(url,usuario).pipe(
        tap( (resp:any) => resp)
      );
  }

  registroVerificacion = (usuario: Usuario) => {
    const url = `${this.baseUrl}/api/usuarios/verificacion`;

    return this.http.post(url,usuario).pipe(
        tap( (resp:any) => resp)
      );
  }

  renovarToken = () => {
    const url = `${this.baseUrl}/api/auth/renovarToken`;

    return this.http.get(url,{
      headers:{
        "token-auth": localStorage.getItem('token') || ''
      }
    }).pipe(
        tap( (resp:any) => resp)
      );
  }
  
  enviarCodigoContrasena  = (usuario: Usuario) => {
    //Definimos ruta del backend en especifico
    const url = `${this.baseUrl}/api/usuarios/enviarCodigoContrasena`;

    return this.http.post(url,usuario).pipe(
        tap( (resp:any) => resp)
      );
  }

  reestablecerContrasena = (nuevaContrasena: any) => {
    const url = `${this.baseUrl}/api/usuarios/cambiarContrasena`;

    return this.http.post(url,nuevaContrasena).pipe(
      tap( (resp:any) => resp)
    );
  }

}
