import { Component, Input} from '@angular/core';
import { Icarousel } from '../../interfaces/i-carousel';

@Component({
  selector: 'app-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss']
})
export class CarouselComponent{

  @Input() imagenes: Icarousel[] = [];  //recibira un arreglo de imagenes
  // @Input() indicador:boolean = true;
  // @Input() controles:boolean = true
  
  constructor() { 

  }

  currentIndex: number = 0;
  totalSlides: number = 3;
  intervalId: any;

  ngOnInit(): void {
    // this.startCarousel();    
  }

  ngOnDestroy() {
    this.stopCarousel();
  }

  //Metodo para cambiar de slide automaticamente
  startCarousel() {
    this.intervalId = setInterval(() => {
      this.nextSlide();
    }, 10000); // Intervalo de cambio automatico (en milisegundos)
  }

  stopCarousel() {
    clearInterval(this.intervalId);
  }

  nextSlide() {
    this.currentIndex = (this.currentIndex + 1) % this.totalSlides;
  }

  prevSlide() {
    this.currentIndex = (this.currentIndex - 1 + this.totalSlides) % this.totalSlides;
  }

  selectImage(index: number):void{
    this.currentIndex = index;
  }
}
