import { Inject, LOCALE_ID, Pipe, PipeTransform } from "@angular/core";
import { formatCurrency, DatePipe, DecimalPipe } from '@angular/common';


@Pipe({
 name: 'dynamic'
})

export class DynamicPipe implements PipeTransform {

 constructor(
   @Inject(LOCALE_ID) public locale: string,
   private datePipe: DatePipe,
   private decimalPipe: DecimalPipe
   ){
  }

 transform = (value: any, ...args: any[]):string => {
   if (!args[0]) {
     return value;
   }
   return this.evaluarPipe(value,args[0].toLowerCase());
 }

 evaluarPipe = (valor: any, pipe:string) => {

   switch (pipe) {
     case 'uppercase':
       valor = valor?.toUpperCase();
       break;
     case 'lowercase':
       valor = valor?.toLowerCase();
       break;
     case 'currency':
       valor = formatCurrency(valor,this.locale,'$')
       break;
     case 'date':
       valor = this.datePipe.transform(valor,'dd/MM/yyyy')
       break;
     case 'datetime':
       valor = this.datePipe.transform(valor,'dd/MM/yyyy hh:mm a')
       break;
     case 'number':
       valor = this.decimalPipe.transform(valor)
       break;
      case 'phone':
        valor.toString().replace(/\D/g, '');

        let foneFormatado = '';

        if (valor.length > 12) {
          foneFormatado = valor.replace(/(\d{2})?(\d{2})?(\d{5})?(\d{4})/, '+$1 ($2) $3-$4');

        } else if (valor.length > 11) {
          foneFormatado = valor.replace(/(\d{2})?(\d{2})?(\d{4})?(\d{4})/, '+$1 ($2) $3-$4');

        } else if (valor.length > 10) {
          foneFormatado = valor.replace(/(\d{2})?(\d{5})?(\d{4})/, '($1) $2-$3');

        } else if (valor.length > 9) {
          foneFormatado = valor.replace(/(\d{3})?(\d{3})?(\d{4})/, '($1) $2-$3');

        } else if (valor.length > 5) {
          foneFormatado = valor.replace(/^(\d{2})?(\d{4})?(\d{0,4})/, '($1) $2-$3');

        } else if (valor.length > 1) {
          foneFormatado = valor.replace(/^(\d{2})?(\d{0,5})/, '($1) $2');
        } else {
          if (valor !== '') { foneFormatado = valor.replace(/^(\d*)/, '($1'); }
        }
        valor = foneFormatado;
        break;  
     default:
       break;
   }
   return valor;
 }
}
