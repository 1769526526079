import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { validarMenuGuardFn } from './guards/validar-menu.guard';
import { NotFoundComponent } from './modulos/utilidades/components/not-found/not-found.component';


const routes: Routes = [
  {
    path: 'principal' ,
    // canActivate: [validarMenuGuardFn],
    loadChildren: () => import('./modulos/principal/principal.module').then(m => m.PrincipalModule)
  },
  {
    path: 'auth' ,
    loadChildren: () => import('./modulos/auth/auth.module').then(m => m.AuthModule)
  },
  {
    path:'config',
    canActivate: [validarMenuGuardFn],
    loadChildren: () => import('./modulos/configuraciones/configuraciones.module').then(m => m.ConfiguracionesModule)
  },
  {
    path:'rh',
    // canActivate: [validarMenuGuardFn],
    loadChildren: () => import('./modulos/recursos-humanos/recursos-humanos.module').then(m => m.RecursosHumanosModule)
  },
  {
    path:'utilidades',
    loadChildren: () => import('./modulos/utilidades/utilidades.module').then(m => m.UtilidadesModule)
  },
  {
    path:'sistemas',
    canActivate: [validarMenuGuardFn],
    loadChildren: () => import('./modulos/sistemas/sistemas.module').then(m => m.SistemasModule)
  },
  {
    path:'panel',
    // canActivate: [validarMenuGuardFn],
    loadChildren: () => import('./modulos/panel-empleados/panel-empleados.module').then(m => m.PanelEmpleadosModule)
  },
  {
    path:'organizacion',
    canActivate: [validarMenuGuardFn],
    loadChildren: () => import('./modulos/organizacion/organizacion.module').then(m => m.OrganizacionModule)
  },
  {
    path:'notFound',
    component: NotFoundComponent
  },
  {
    path: '**',
    redirectTo: 'auth'
    // component: NotFoundComponent
  }
    
];

@NgModule({
    imports: [
      RouterModule.forRoot(routes),
    ],
    exports: [RouterModule]
  })
  
  export class AppRoutingModule { }
  