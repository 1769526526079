import {  CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { RolService } from '../modulos/configuraciones/services/rol.service';
import { MenuService } from '../modulos/utilidades/services/menu.service';
import { AuthService } from '../modulos/auth/services/auth.service';
import { RespuestaGeneralInt } from '../modulos/utilidades/interfaces/respuestaGeneral';


@Injectable({
  providedIn: 'root'
})

export class validarMenuGuardFn implements CanActivate{
  constructor(private rolServicio: RolService,
              private router: Router,
              private menuServicio: MenuService,
              private authServicio: AuthService

              ){
  }


  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot){
    
    const token = await this.renovarToken(route, state);

    if (token.error) {
      localStorage.clear();
      this.router.navigateByUrl("/auth/login");
      return false;
    }

    localStorage.setItem("token",token?.payload);

    const menu = await this.obtenerMenu(route, state);
    if (!menu) {
        this.router.navigateByUrl("/notFound");
    }

    return menu;
  }


  async renovarToken(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Promise<RespuestaGeneralInt>   {
    
      if (state.url === '/notFound') {
        return new Promise<RespuestaGeneralInt>((resolve,reject)=>resolve({
          error: true,
          msg:"undefined",
          payload: undefined
        }));
      }
      
      return  new Promise<RespuestaGeneralInt>((resolve, reject) => {
        this.authServicio.renovarToken().subscribe({
          next: resOK => {
            resolve(resOK);
          },
          error: err => {
            resolve({
              error: true,
              msg: "",
              payload: err
            });
          }
        })
      });

  }

  async obtenerMenu(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Promise<boolean>{

      if (state.url === '/notFound') {
        return new Promise<boolean>((resolve,reject)=>resolve(false));
      }
      
      return  new Promise<boolean>((resolve, reject) => {
        this.rolServicio.obtenerMenuPorRol( state.url ).subscribe({
          next: resOK => {
            this.menuServicio.asignarMenuActual(resOK?.payload)
            resolve(resOK);
          },
          error: err => {
            resolve(false);
          }
        })
      });
  }

}