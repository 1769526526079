<div class="row">
    <div class="col-md-12">
      <mat-card>
        <mat-card-content>
          <div class="flex-centered">
            <h2 class="title">404 Pagina no encontrada</h2>
            <small class="sub-title">La página que estabas buscando no existe</small>
            <button mat-raised-button fullWidth (click)="irInicio()"  class="home-button"> 
                Regresar a Inicio
            </button> 
          </div>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
