<div class="mat-elevation-z7" style="margin-bottom: 1%">

    <mat-accordion multi>

        <mat-expansion-panel  [expanded]="expandir">
            <mat-expansion-panel-header>
              <mat-panel-title> {{encabezado}} </mat-panel-title>
              <mat-panel-description>
                <div style="visibility: hidden"></div>
                <mat-icon>{{icono}}</mat-icon>
              </mat-panel-description>
            </mat-expansion-panel-header>

            <div class="contenidoPanel">
    
            <div>
            <form [formGroup]="frmFechas">

                <div class="formgrid grid">
    
                    <div class="tipo1" class="col-4 md:col-4" *ngIf="datosTipo1.length > 0">
                        <div>
                            <div>
                                <!-- [valorSeleccionado]="tipoSeleccionado" -->
                                <app-select2-generico
                                [leyenda]="leyenda"
                                tipografia="uppercase"
                                name="tipo1"
                                [datos]="datosTipo1"
                                (obtenerSelect)="cambioDeSelect2($event)"
                                [valorSeleccionado]="seleccionTipo1"
                                ></app-select2-generico>
                            </div>
                            </div>
                    </div>

                    <div class="tipo2" *ngIf="datosTipo2.length" class="col-3 md:col-3">
                        <!-- <div
                            *ngIf="datosTipo2.length > 0"
                            > -->
                            <div>
                                <!--[valorSeleccionado]="tipoSeleccionado" -->
                                <app-select2-generico
                                [leyenda]="leyenda2"
                                tipografia="uppercase"
                                name="tipo2"
                                [datos]="datosTipo2"
                                (obtenerSelect)="cambioDeSelect2($event)"
                                [valorSeleccionado]="seleccionTipo2"
                                ></app-select2-generico>
                            </div>
                        <!-- </div> -->
                    </div>

                    <div class="texto1" class="col-4 md:col-4" *ngIf="texto1">
                        <div>
                            <div>
                                <mat-form-field appearance="outline" class="w-full">
                                    <mat-label> {{leyendaTipo1}}</mat-label>
                                        <input matInput formControlName="texto1">
                                </mat-form-field>
                                
                            </div>
                        </div>
                    </div> 
        

                <div class="fechas" class="col-3 md:col-3" *ngIf="mostrarFecha">
                    <mat-form-field style="width: 100%" appearance="outline">
                        <mat-label>POR RANGO DE FECHA</mat-label>
                        <mat-date-range-input [rangePicker]="picker">
                            <input
                            matStartDate
                            formControlName="inicial"
                            placeholder="Fecha Inicial"
                            />
                            <input
                            matEndDate
                            formControlName="final"
                            placeholder="Fecha Final"
                            />
                        </mat-date-range-input>
            
                        <mat-datepicker-toggle
                            matSuffix
                            [for]="picker"
                        ></mat-datepicker-toggle>
                        <mat-date-range-picker
                            touchUi
                            #picker
                            classList="calendarioRange"
                        ></mat-date-range-picker>
                        </mat-form-field>
                </div>

                
                <div class="btnBuscar">
                    <button mat-raised-button color="primary" 
                    (click)="aplicarFiltro()"
                    [disabled]="!frmFechas.valid">Buscar</button>

                </div>


            </div>

            </form>

            </div>
    
            </div>
            
        </mat-expansion-panel>

    </mat-accordion>

</div>  