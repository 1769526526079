import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { RespuestaGeneralInt } from '../../interfaces/respuestaGeneral';
import { FiltroGeneralService } from '../../services/filtro-general.service';
import { InputBusqueda } from '../../interfaces/InputBusqueda';

@Component({
  selector: 'app-filtro-general',
  templateUrl: './filtro-general.component.html',
  styleUrls: ['./filtro-general.component.scss']
})
export class FiltroGeneralComponent {


  @Input("encabezado") encabezado: string = "Filtro default";
  @Input("leyenda") leyenda: string = "Buscar";
  @Input("leyenda2") leyenda2: string = "Buscar";
  @Input("icono") icono: string = "search";
  @Input("expandir") expandir: boolean = false;
  @Input("mostrarFecha") mostrarFecha: boolean = false;
  @Input("datosTipo1") datosTipo1: any[] = [];
  @Input("datosTipo2") datosTipo2: any[] = [];
  @Input("texto1") texto1: boolean = false;
  @Input("leyendaTipo1") leyendaTipo1: string = "POR NOMBRE O APELLIDO";
  // @Input("seleccionTipo1") seleccionTipo1: any[] = [];
  
  @Input() seleccionTipo1:{id:number,nombre:string} | undefined;
  @Input() seleccionTipo2:{id:number,nombre:string} | undefined;


  @Output('consultarDatos') consultarDatos: any = new EventEmitter<FormGroup>();

  // @Output('consultarDatos') consultarDatos = new EventEmitter<any>();
  @Input() formulario:InputBusqueda[] = [];

  frmFechas: FormGroup = this.fb.group({
    inicial: [],
    final: [],
    texto1: [],
    // tipo1: [],
    // tipo2:[]
  });

  constructor(
    private fb: FormBuilder,
    private filtroServicio: FiltroGeneralService
  ){

    filtroServicio.formulario.subscribe({

      next: (datos) => {
        // console.log('datos',datos)

        datos.forEach((item: any) => {
          // console.log('item', item);

              // this.frmFechas.setControl(item.nombre, this.fb.control(item?.valor, [Validators.required]));
            if (item?.requerido) {
              this.frmFechas.setControl(item.nombre, this.fb.control(item?.valor, [Validators.required]));
            }

              if (item.nombre === 'tipo1') {
                this.seleccionTipo1 = datos.find(e => e.nombre === 'tipo1')?.valor || {id: undefined, nombre: undefined};
              }

              if (item.nombre === 'tipo2') {
                this.seleccionTipo2 = datos.find(e => e.nombre === 'tipo2')?.valor || {id: undefined, nombre: undefined};
              }
              
              if (item.nombre === 'texto1') {
                this.seleccionTipo2 = datos.find(e => e.nombre === 'texto1')?.valor || {id: undefined, nombre: undefined};
              }

            });
      }
    })

  } //fin constructor


  aplicarFiltro = () => {

    // console.log("t1",this.datosTipo1)
    // console.log("t2",this.datosTipo2)
    // const datosRetornar = this.frmFechas.value;
    const datosRetornar = this.frmFechas;
    // console.log(datosRetornar)

    this.consultarDatos.emit(datosRetornar)
  }

  //Metodo para actualizar select 
  cambioDeSelect2 = (opcion: RespuestaGeneralInt) => {
    // console.log(opcion)
    if (!opcion.error) {
      this.frmFechas.controls[opcion?.msg]?.setValue(opcion.payload);
    }

    this.consultarDatos.emit(opcion)
  }


}
