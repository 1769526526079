import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { tap } from 'rxjs/operators';
import { IRol } from '../objects/rol.interface';

@Injectable({
  providedIn: 'root'
})
export class RolService {

  private baseUrl = environment.base_url;

  constructor(
    private http: HttpClient
  ) { }

  consultarRoles = () => {
    return this.http.get(`${this.baseUrl}/api/roles/`,{
      headers:{
        "token-auth": localStorage.getItem('token') || ''
      }
    }).pipe(
      tap( (respuesta:any) => respuesta)
    )
  }

  consultarRolesID = (rol: IRol) => {
    return this.http.get(`${this.baseUrl}/api/roles/${rol.id}`,{
      headers:{
        "token-auth": localStorage.getItem('token')  || ''
      }
    }).pipe(
      tap( (respuesta:any) => respuesta)
    )
  }

  registrarRol = (rol: IRol) => {
    return this.http.post(`${this.baseUrl}/api/roles/`,rol,{
      headers:{
        "token-auth": localStorage.getItem('token')  || ''
      }
    }).pipe(
      tap( (respuesta:any) => respuesta)
    )
  }

  editarRol = (rol: IRol) => {
    return this.http.put(`${this.baseUrl}/api/roles/${rol.id}`,rol,{
      headers:{
        "token-auth": localStorage.getItem('token')  || ''
      }
    }).pipe(
      tap( (respuesta:any) => respuesta)
    )
  }

  //Consultar tablas de relacion
  obtenerMenuPorRol = (ruta: string) => {
    return this.http.post(`${this.baseUrl}/api/rolacciones/obtenerMenu`,{
      ruta: ruta
    },{
      headers:{
        "token-auth": localStorage.getItem('token') || ''
      }
    }).pipe(
      tap( (respuesta:any) => respuesta)
    )
    
  }

  //guardar permisos por rol/usuario

  registrarPermisos = (datos: any) => {
    return this.http.post(`${this.baseUrl}/api/rolacciones/asignarPermisos`, datos,{
      headers:{
        "token-auth": localStorage.getItem('token') || ''
      }
    }).pipe(
      tap( (respuesta:any) => respuesta)
    )
    
  }


}