<div class="carousel">

  <button mat-icon-button (click)="prevSlide()" color="primary">
    <mat-icon class="btn_icon">navigate_before</mat-icon>
  </button>

  <button mat-icon-button (click)="nextSlide()" color="primary">
      <mat-icon class="btn_icon">navigate_next</mat-icon>
  </button>

    <div class="contenedor">
        <div *ngFor="let img of imagenes; let i=index"  [ngStyle]="{ 'display': currentIndex === i ? 'block' : 'none' }">
                <img [src]="img.imagen | baseUrl" [alt]="img.titulo" class="imagen">
        </div>   
    </div>

    <!-- <button mat-icon-button (click)="prevSlide()" color="primary">
      <mat-icon>navigate_before</mat-icon>
    </button>

    <button mat-icon-button (click)="nextSlide()" color="primary">
        <mat-icon>navigate_next</mat-icon>
    </button> -->

    <div *ngIf="totalSlides" class="carousel_dot_container">
      <span *ngFor="let dot of imagenes; let i=index" 
        class="dot"
        [ngClass]="{'active': currentIndex === i}"
        (click)="selectImage(i)"
      >  

      </span>
    </div>
    
  </div>
  
  