import { NgModule } from '@angular/core';
import { CommonModule, DatePipe, DecimalPipe } from '@angular/common';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { TablaBaseComponent } from './components/tabla-base/tabla-base.component';
import { MaterialModule } from 'src/app/material/material.module';
import { PipesModule } from 'src/app/pipes/pipes.module';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { Select2GenericoComponent } from './components/select2-generico/select2-generico.component';
import { FiltroGeneralComponent } from './components/filtro-general/filtro-general.component';
import { CarouselComponent } from './components/carousel/carousel.component';


@NgModule({
  declarations: [
    SpinnerComponent,
    TablaBaseComponent,
    NotFoundComponent,
    Select2GenericoComponent,
    FiltroGeneralComponent,
    CarouselComponent
  ],
  imports: [
    CommonModule,
    MaterialModule,
    PipesModule,
    
  ],
  exports:[
    SpinnerComponent,
    TablaBaseComponent,
    NotFoundComponent,
    Select2GenericoComponent,
    FiltroGeneralComponent,
    CarouselComponent
  ],
  providers:[DatePipe,DecimalPipe]


})
export class UtilidadesModule { }