import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { finalize, Observable, tap } from "rxjs";
import { SpinnerService } from '../spinner.service';


@Injectable({
  providedIn: 'root'
})
export class HttpInterceptorService implements HttpInterceptor{

  constructor(
    private spinnerServicio:SpinnerService
  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!this.spinnerServicio.cargando.getValue()) {
      this.spinnerServicio.mostrar();
    }
     return next.handle(req).pipe(
      finalize( () =>{
         if (this.spinnerServicio.cargando.getValue()) {
          this.spinnerServicio.ocultar()
        }
      })
    );
  }

}