import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DynamicPipe } from './dynamic.pipe';
import { BaseUrlPipe } from './baseurl.pipe';


@NgModule({
  declarations: [
    DynamicPipe,
    BaseUrlPipe
  ],
  imports: [
    CommonModule
  ],
  exports:[
    DynamicPipe,
    BaseUrlPipe
  ]
})
export class PipesModule { }
